<template>
  <youtube
    :style="`${maxHeight}${fullScreenClass}`"
    class="yt-iframe"
    :video-id="getId()"
    ref="youtube"
    :resize="true"
    @playing="playing"
    :attrs="{'allowfullscreen': true}"
    allow="fullscreen"
  ></youtube>
</template>

<script>
import notify from "@/services/libs/notificacao";

export default {
  name: "VideoPlayer",
  props: ["porcentagem", "isMobile", "apiLesson"],
  computed: {
    player() {
      const player = this.$refs.youtube.player;
      return player;
    },
    maxHeight() {
      if (this.isFullscreen) {
        return '';
      }
      return 'width: 100%;'
    },
    fullScreenClass () {
      if (this.isFullscreen) {
        return 'position: fixed; top:0; left:0; right:0; bottom: 0;'
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      isFullscreen: false,
    }
  },
  methods: {
    enterFullscreen() {
      if (this.$refs.youtube.requestFullscreen) {
        this.$refs.youtube.requestFullscreen();
      } else if (this.$refs.youtube.webkitRequestFullscreen) {
        this.$refs.youtube.webkitRequestFullscreen();
      } else if (this.$refs.youtube.msRequestFullscreen) {
        this.$refs.youtube.msRequestFullscreen();
      }
    },
    getId() {
      return this.$youtube.getIdFromUrl(this.apiLesson.source);
    },
    playVideo() {
      this.player.playVideo();
    },
    async playing() {
    //   console.log("o/ we are watching!!!");
    },
    async calculaPorcentagem(type) {
      let totalTime = await this.player.getDuration();
      let that = this;
      this.player.getCurrentTime().then((time) => {
        let calc = (time / totalTime) * 100;
        calc = parseInt(calc, 10);

        if (calc >= parseInt(that.porcentagem, 10)) {
          if (type === "concluir") {
            that.$root.$emit("liberaBtn");
          } else if (type === "calcula") {
            that.$root.$emit("calculaBtn");
          } else {
            that.$root.$emit("liberaBtnNext");
          }
        } else {
          notify("erro", that.$t("player.errors_players.msg3"));
        }
      });
    },
  },
  mounted() {
    this.$root.$on("clicouConcluir", (data) => {
      if (data === "youtube") {
        this.calculaPorcentagem("concluir");
      }
    });
    this.$root.$on("clicouNext", (data) => {
      if (data === "youtube") {
        this.calculaPorcentagem("next");
      }
    });
    this.$root.$on("calcula", (data) => {
      if (data === "youtube") {
        this.calculaPorcentagem("calcula");
      }
    });
    const self = this;
    document.addEventListener("fullscreenchange", function() {
    if (document.fullscreenElement) {
      self.enterFullscreen();
      self.isFullscreen = true;
    } else {
      self.isFullscreen = false;
    }
  });
  },
};
</script>
<style lang="scss">
.video-js {
  width: 100% !important;
  // height: 100% !important;
  z-index: 999999;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}

.toZindex .video-js {
  z-index: 99999999999999999;
}

.hide {
  display: none !important;
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-js .vjs-big-play-button {
  left: 45% !important;
  top: 48% !important;
}

.yt-iframe {
  width: 100%;
  aspect-ratio: 16/9;
}
</style>
